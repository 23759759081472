.grey-container {
  background-color: #F8FBFE;

  .buy-section {
    margin-top: 100px;
    justify-content: space-between;
    align-items: center;
    padding: 25px 83px;
    background-color: #ffffff;
    box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);
    border-radius: 16px;
    flex-wrap: wrap;

    .left-section {
      max-width: 602px;
    }

    .right-section p:not(:first-child) {
      margin-top: 20px;
    }

    .right-section button {
      width: 100%;
      margin-top: 20px;

      span {
        margin: auto;
      }
    }
  }

  .docs-section {
    margin: 100px auto  100px;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;

    a {
      text-decoration: none;
      cursor: pointer;
    }

    a:hover {
      background-color: transparent;
      color: #FFFFFF;
    }

    .doc-card {
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);
      border-radius: 16px;

      padding: 40px 0;

      .doc-icon {
        width: 90px;
        height: 84.11px;
      }


      p {
        padding-top: 10px;
        max-width: 252px;
        min-width: 252px;
        margin: 0 24px;
      }
    }
  }

}
@import "bootstrap";
@import "header";
@import "footer";
@import "description";
@import "grey-section";
@import "svgs";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//------------------------------------
//          Custom colors
//------------------------------------

$--ui-color-blue: #4482FF;
$--ui-color-blue-dark: #1849A9;
$--ui-color-blue-med: #92b4f6;
$--ui-color-blue-light: #EEF4FF;
$--ui-color-blue-bg: #e9f0fd;

$--ui-color-500: #172133;
$--ui-color-400: #57626D;
$--ui-color-300: #707A83;

$--ui-color-inversive-500: #ffffff;
$--ui-color-inversive-400: #f5f7fa;

$--ui-color-auth-background: #f8f8f8;

$--ui-color-black-light: #48556e;
$--ui-color-gray-dark: #8d95a5;

$--ui-color-gray: #d4d9e2;
$--ui-color-gray-light: #e6e9f0;
$--ui-color-grey-med: #8D95A5;


$--ui-color-green: #3AC267;
$--ui-color-green-dark: #268045;
$--ui-color-green-light: #93DDAC;
$--ui-color-green-bg: rgba(223, 250, 232, 0.3);


$--ui-color-red: #DF2059;

// -----------------------------------
//              Helper
//-----------------------------------

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

$encoding-reference: (
                ('<', '%3C'),
                ('>', '%3E'),
                ('"', '\''),
                ('#', '%23'),
                ('&', '%26')
);

@function svg-encode($svg) {
  @each $char, $encoded in $encoding-reference {
    $svg: str-replace($svg, $char, $encoded);
  }
  @return 'data:image/svg+xml,' + $svg;
}

.text-align-center {
  text-align: center;
}

.mt-40 {
  margin-top: 40px;
}

.mt-20 {
  margin-top: 20px;
}

//------------------------------------
//             Shortcuts
//------------------------------------

%text-bold {
  font-style: normal;
  font-weight: 600;
}

%text-regular {
  font-style: normal;
  font-weight: 400;
}

%one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

//------------------------------------
//          Service Styles
//------------------------------------

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif, sans-serif;
  padding: 0;
  margin: 0;
  font-size: 18px;
  background-image: url("../images/background_description.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 0;
  background-position-x: center;
}

p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  background-color: transparent;
  color: transparent;
}

.landing {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

//-----------------------------------
//            Toast
//-----------------------------------

.toast-black-bg {
  background-color: #48556E;
  border-radius: 8px;
  padding: 12px 16px;
  color: $--ui-color-inversive-500;
}

.toast-white-bg {
  border: 0;
  background-color: $--ui-color-inversive-500;
  border-radius: 8px;
  padding: 12px 16px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
}

.toast .icon__wrapper {
  margin-right: 16px;
    min-height: 24px;
    max-height: 24px;
    min-width: 24px;
    max-width: 24px;
}

.toast-holder {
  position: fixed;
  top: 160px;
  right: 28px;
}

.toast {
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  &.toast__ {

    &success .icon__wrapper {
      background: url(svg-encode($success-icon)) no-repeat center center;
    }

    &warning .icon__wrapper {
      background: url(svg-encode($warning-icon)) no-repeat center center;
    }

    &info .icon__wrapper {
      background: url(svg-encode($info-icon)) no-repeat center center;
    }

    &error .icon__wrapper {
      background: url(svg-encode($error_toast)) no-repeat center center;
    }
  }
}

//-----------------------------------
//            Typography
//-----------------------------------

.body {
  &-13 {
    @extend %text-regular;
    font-size: 13px;
    line-height: 20px;

    &-bold {
      @extend %text-bold;
      font-size: 13px;
      line-height: 20px;
    }
  }

  &-15 {
    @extend %text-regular;
    font-size: 15px;
    line-height: 24px;

    &-bold {
      @extend %text-bold;
      font-size: 15px;
      line-height: 24px;
    }
  }

  &-18 {
    @extend %text-regular;
    font-size: 18px;
    line-height: 28px;

    &-bold {
      @extend %text-bold;
      font-size: 18px;
      line-height: 28px;
    }
  }

  &-48 {
    @extend %text-regular;
    font-size: 48px;
    line-height: 59px;

    &-bold {
      @extend %text-bold;
      font-size: 48px;
      line-height: 59px;
    }
  }
}

//-----------------------------------
//              Colors
//-----------------------------------

.color {
  &-blue {
    color: $--ui-color-blue;
  }

  &-black {
    color: #222222;
  }

  &-text-primary {
    color: #2E2E3A;
  }

  &-300 {
    color: $--ui-color-300;
  }

  &-400 {
    color: $--ui-color-400;
  }

  &-500 {
    color: $--ui-color-500;
  }

  &-inversive-500 {
    color: $--ui-color-inversive-500;
  }
}

// -----------------------------------
//              Button
//-----------------------------------

.btn__default-primary-rounded, .btn__default-light-rounded {
  @extend %one-line;
  background: $--ui-color-inversive-500;
  border: 1px solid $--ui-color-inversive-500;
  border-radius: 39px;
  padding: 12px 24px;

  .btn__default-text {
    color: $--ui-color-blue;
  }
}

.btn__default-primary {
  @extend %one-line;
  background: $--ui-color-blue;
  border: 1px solid $--ui-color-blue;
  border-radius: 8px;
  padding: 12px 24px;
  box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);

  .btn__default-text {
    color: $--ui-color-inversive-500;
  }
}

// -----------------------------------
//              Mobile
//-----------------------------------

@media (max-width: 1400px) {
  .description-container .tokenomic_container .tokenomic_image {
    width: 530px;
  }

  .description-container .tokenomic_container .right-section .table-token {
    width: 400px;
  }

  .docs-section {
    margin: 90px auto;

    a:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}

// -----------------------------------
//          breakpoint: LG - ≥992px
//------------------------------------
@include media-breakpoint-down(lg) {
  .description-container {
    padding-top: 58px;
    padding-bottom: 60px;
  }


  .condition_container {
    justify-content: center !important;
    margin-top: 90px !important;

    .condition_text {

      .body-48-bold {
        margin-top: 10px !important;
      }
    }

    .condition_image {
      margin-top: 30px;
    }
  }

  .description-container .tokenomic_container .tokenomic_image {
    width: 562px;
  }

  .tokenomic_container {
    justify-content: center !important;
    margin-top: 90px !important;

    .right-section {
      margin-top: 30px;
    }
  }

  .grey-container {
    .buy-section {
      margin-top: 90px;
      justify-content: center;

      .left-section {
        text-align: center;
        margin-bottom: 40px;
      }
    }
  }
}

// -----------------------------------
//          breakpoint: md - ≥768px
//------------------------------------
@include media-breakpoint-down(md) {
  body {
    background-image: url("../images/background_mobile.png");
    background-position-y: -73px;
  }

  .description-container .tokenomic_container .right-section .table-token .content-table .item-table p:last-child {
    width: 100px;
  }

  .body-15-bold {
    font-size: 13px;
    line-height: 20px;
  }

  .body-48-bold {
    font-size: 24px;
    line-height: 32px;
  }

  .body-18-bold {
    font-size: 15px;
    line-height: 24px;
  }

  .body-18 {
    font-size: 15px;
    line-height: 24px;
  }

  .body-15 {
    font-size: 15px;
    line-height: 24px;
  }

  .condition_image {
    width: 470px;
  }


  .description-container .tokenomic_container .tokenomic_image {
    width: 483.22px;
    height: 450px;
  }

  .docs-section {
    justify-content: center !important;
  }

  .landing-footer {
    .container {
      justify-content: center;
      padding-top: 60px;
      padding-bottom: 60px;

      .right-section {
        margin-top: 20px;
        width: 282px;
      }
    }
  }
}

@media (max-width: 576px) {

  body {
    background-position-y: 0;
  }

  .header {
    padding: 25px 16px;

    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .logo img {
    width: 41px;
    height: 32px;
    margin-right: 10px;
  }

  .condition_image {
    width: 343px !important;
  }

  .description-container .tokenomic_container {
    .right-section {
      width: 100%;
    }
    .tokenomic_image {
      margin-top: 20px;
      height: 268px !important;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }



  .table-token {
    width: 100% !important;
  }

  .description-container .tokenomic_container .right-section .table-token .content-table {
    padding: 30px 14px;
  }

  .grey-container .buy-section {
    padding: 40px 20px;
    width: 90%;
  }

}

@media (max-width: 506px) {
  .description-container .tokenomic_container {
    .left-section {
      width: 100%;
    }
    .tokenomic_image {
      width: 343px;
    }
  }
}
.header {
  padding: 26px 26px;
  background: rgba(233, 240, 253, 0.3);
  backdrop-filter: blur(7.5px);

  .container {
    flex-wrap: wrap;
  }
}

.logo img {
  margin-right: 14px;
}


